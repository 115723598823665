import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import Seo from "../components/seo"
import Hero from "../components/Index/hero"
import BestServices from "../components/Index/bestServices"
import HowItWorks from "../components/Index/howItWorks"
import PopularServices from "../components/Index/popularServices"
import WhyHire from "../components/Index/whyHire"
import CompanyReviewSection from "../components/Index/companyReviewSection"
import WhatHelp from "../components/Index/whatHelp"
import Faq from "../components/Index/faq"
import Description from "../components/Index/description"
import Footer from "../components/Layout/footer"
import Navbar from "../components/Layout/navbar"
import { useEffect } from "react"
import { setCountryImage } from "../services/imageHelper"

const CountryTemplate = ({ data, pageContext }) => {
  const country = data.allCountry.nodes[0]

  useEffect(() => {
    setCountryImage(`${process.env.GATSBY_WRANGLER_URL}/${country.iso}/_${country.iso}.jpg`)
  })

  return (
    <Layout page="country">
      <Navbar locationName={country.niceName} />
      <Seo title={"Best " + country.niceName + " Home Services - Handyman, Lawn & More"} />
      <Hero locationName={country.niceName} />
      <BestServices countryCode={pageContext.countryCode} regions={data.allRegion.nodes} country={country} />
      <HowItWorks />
      <PopularServices locationName={country.niceName} />
      <WhyHire />
      <CompanyReviewSection />
      <WhatHelp />
      <Faq />
      <Description />
      <Footer locationName={country.niceName} />
    </Layout>
  )
}

export default CountryTemplate

export const countryQuery = graphql`
  query ($countryCode: String!) {
    allCountry(filter: { iso: { eq: $countryCode } }) {
      nodes {
        id
        iso
        niceName
      }
    }
    allRegion(filter: { countryCode: { eq: $countryCode } }) {
      nodes {
        id
        name
        code
      }
    }
  }
`
